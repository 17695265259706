import React, { Component } from 'react';
import Titulo from '../../components/Texto/Titulo';
import Input from '../../components/Inputs/Simples';
import Checkbox from '../../components/Inputs/Checkbox';
import Button from '../../components/Button/Simples';
import AlertDanger from '../../components/Alert/Danger'
import '../../../css/index.css'

import { connect } from 'react-redux';
import * as actions from '../../actions/'
import { api, versao } from '../../config'

class Login extends Component {
    state = {
        email: '',
        password: '',
        opcaoLembrar: true,
        erros: {}
    }

    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value })
        this.validate()
    }
    onChangeCheckBox = (field) => this.setState({ [field]: !this.state[field] })

    handleLogin() {
        const { email, password, opcaoLembrar } = this.state;
        if (!this.validate()) return;
        this.props.handleLogin({ email, password, opcaoLembrar }, (error) => {
            this.setState({ erros: { ...this.state.erros, form: error } })
        })
    }

    validate() {
        const { email, password } = this.state;
        const erros = {};
        if (!email) erros.email = 'Preencha aqui com seu e-mail'
        if (!password) erros.password = 'Preencha aqui com sua senha'
        this.setState({ erros });
        return !(Object.keys(erros).length > 0);
    }

    render() {
        const { email, password, opcaoLembrar, erros } = this.state;
        return (
            <div className='Login flex flex-center'>
                <div className='Card'>
                    <div className='flex vertical flex-center'>
                        <Titulo tipo='h1' titulo='Loja TI' />
                        <p>Faça seu login abaixo</p>
                    </div>
                    <br /><br />
                    <AlertDanger error={erros.form} />

                    <Input
                        label='E-mail'
                        value={email}
                        type='email'
                        error={erros.email}
                        onChange={(ev) => this.onChangeInput('email', ev)} />

                    <Input
                        label='Senha'
                        value={password}
                        error={erros.password}
                        type='password'
                        onChange={(ev) => this.onChangeInput('password', ev)} />

                    <div className='flex'>
                        <div className='flex-1'>
                            <Checkbox
                                value={opcaoLembrar}
                                onChange={(e) => this.onChangeCheckBox('opcaoLembrar')}
                                label='Lembar?' />
                        </div>
                        <div className='flex-1 flex flex-end'>
                            {/* <Link to='/recuperar-senha'><smal>Esqueceu sua senha?</smal></Link> */}
                            <a href={`${api}/${versao}/api/usuarios/recuperar-senha`}>
                                <small>Esqueceu sua senha?</small>
                            </a>
                        </div>
                    </div>
                    <br /><br />
                    <div className='flex flex-center'>
                        <Button type='success' label='Entrar' onClick={() => this.handleLogin()} />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, actions)(Login);