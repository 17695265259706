import React, { Component } from 'react';
import Titulo from '../../components/Texto/Titulo'
import Tabela from '../../components/Tabela/Simples'
import { connect } from 'react-redux'
import * as actions from '../../actions/categorias'
import { Link } from 'react-router-dom'

class Categorias extends Component {
    state = {
        atual: 0,
    }

    getCategorias() {
        const { usuario } = this.props;
        if (!usuario) return null;
        this.props.getCategorias(usuario.loja);
    }

    componentDidMount() {
        this.getCategorias();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.usuario && this.props.usuario) this.getCategorias();
    }

    renderNovaCategoria() {
        return (
            <Link
                className='button button-success button-small'
                to="/categorias/nova">
                <i className="fas fa-plus"></i>
                <small>&nbsp; Nova Categoria</small>
            </Link>
        )
    }

    render() {
        const { categorias } = this.props;
        const dados = [];
        (categorias || []).forEach((item) => {
            dados.push({
                'Categoria': item.nome,
                'Qtd. de Produtos': item.produtos.length,
                'botaoDetalhes': `/categoria/${item._id}`
            })
        })

        return (
            <div className="Categorias full-width">
                <div className='Card'>
                    <Titulo tipo="h1" titulo="Categorias" />
                    {this.renderNovaCategoria()}
                    <br />
                    <br />
                    <Tabela
                        cabecalho={['Categoria', 'Qtd. de Produtos']}
                        dados={dados} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    categorias: state.categoria.categorias,
    usuario: state.auth.usuario
})

export default connect(mapStateToProps, actions)(Categorias)