import { GET_CLIENTES, GET_CLIENTE, REMOVE_CLIENTE, LIMPAR_CLIENTE, GET_CLIENTE_PEDIDO } from '../actions/types';

export default (state = {}, action) => {
    switch (action.type) {
        case GET_CLIENTES:
            return {
                ...state,
                clientes: action.payload.clientes
            }
        case GET_CLIENTE:
            return {
                ...state,
                cliente: action.payload.cliente
            }
        case GET_CLIENTE_PEDIDO:
            return {
                ...state,
                clientePedidos: action.payload.pedidos
            }
        case REMOVE_CLIENTE:
            return {
                ...state,
                cliente: { ...state.cliente, deletado: action.payload.deletado }
            }
        case LIMPAR_CLIENTE:
            return {
                ...state,
                cliente: null
            }
        default:
            return state;
    }
}