export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const GET_PEDIDOS = 'GET_PEDIDOS';
export const GET_PEDIDO = 'GET_PEDIDO';
export const LIMPAR_PEDIDO = 'LIMPAR_PEDIDO';
export const CANCELAR_PEDIDO = 'CANCELAR_PEDIDO';

export const GET_CLIENTES = 'GET_CLIENTES';
export const GET_CLIENTE = 'GET_CLIENTE';
export const GET_CLIENTE_PEDIDO = 'GET_CLIENTE_PEDIDO';
export const REMOVE_CLIENTE = 'REMOVE_CLIENTE';
export const LIMPAR_CLIENTE = 'LIMPAR_CLIENTE';

export const GET_CATEGORIAS = 'GET_CATEGORIAS';
export const GET_CATEGORIA = 'GET_CATEGORIA';
export const LIMPAR_CATEGORIA = 'LIMPAR_CATEGORIA';
export const REMOVE_CATEGORIA = 'REMOVE_CATEGORIA';
export const GET_CATEGORIA_PRODUTOS = 'GET_CATEGORIA_PRODUTOS'

export const GET_PRODUTOS = 'GET_PRODUTOS';
export const GET_PRODUTO = 'GET_PRODUTO';
export const LIMPAR_PRODUTO = 'LIMPAR_PRODUTO';

export const GET_VARIACOES = 'GET_VARIACOES';
export const GET_VARIACAO = 'GET_VARIACAO';
export const LIMPAR_VARIACAO = 'LIMPAR_VARIACAO';
export const REMOVE_VARIACAO = 'REMOVE_VARIACAO';

export const GET_AVALIACOES = 'GET_AVALIACOES';
export const GET_AVALIACAO = 'GET_AVALIACAO';
export const LIMPAR_AVALIACAO = 'LIMPAR_AVALIACAO';
export const REMOVE_AVALIACAO = 'REMOVE_AVALIACAO';

export const GET_CONFIGURACOES = "GET_CONFIGURACOES";
export const LIMPAR_CONFIGURACOES = 'LIMPAR_CONFIGURACOES';